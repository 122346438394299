<template>
  <div class="document-text-field-renderer">
    <div v-html="$translate(props.document.content)" />
  </div>
  <component
    :is="confirmationRenderer"
    :key="document.id"
    :document="document"
    @confirm="onConfirm"
    @back="$emit('back')"
  />
</template>
<script setup lang="ts">
import { type PropType, computed } from 'vue';
import type { VisitDocumentType, IVisitDocument, ScannerDocument } from '@einfachgast/shared';
import SignatureConfirmation from '@/components/dynamic-form/renderers/document/SignatureConfirmation.vue';
import NoConfirmation from '@/components/dynamic-form/renderers/document/NoConfirmation.vue';
import CheckboxConfirmation from '@/components/dynamic-form/renderers/document/CheckboxConfirmation.vue';
import { translate } from '@/plugins/translator';

const emit = defineEmits<{
  (e: 'confirm', value: IVisitDocument): void,
  (e: 'back'): void}
>();

const props = defineProps({
  document: {
    type: Object as PropType<ScannerDocument>,
    required: true,
    default: () => ({} as ScannerDocument),
  },
});

const onConfirm = (value: string | boolean) => {
  let visitDocumentType = 'none';
  switch (props.document.confirmationType) {
    case 'checkbox':
    case 'optionalCheckbox':
      visitDocumentType = 'checkbox';
      break;
    case 'signature':
      visitDocumentType = 'signature';
      break;
    default:
      break;
  }
  const visitDoc: IVisitDocument = {
    name: translate(props.document.name),
    id: props.document.id,
    path: translate(props.document.content),
    checkboxLabel: translate(props.document.confirmationCaption),
    type: visitDocumentType as VisitDocumentType,
    documentType: props.document.type,
    value,
  };
  emit('confirm', visitDoc);
};

const confirmationRenderer = computed(() => {
  if (props.document.confirmationType === 'signature') {
    return SignatureConfirmation;
  }
  if (['optionalCheckbox', 'checkbox'].includes(props.document.confirmationType)) {
    return CheckboxConfirmation;
  }
  return NoConfirmation;
});

</script>
<style lang="scss">
.document-text-field-renderer {
  line-height: 1.2;
  user-select: none;
  word-break: break-word;
  h1 {
    font-size: 2em;
    font-weight: bold;
  }
  h2 {
    font-size: 1.5em;
    font-weight: bold;
  }
  h3 {
    font-size: 1.17em;
    font-weight: bold;
  }
  h4 {
    font-size: 1em;
    font-weight: bold;
  }
  p, ol, ul, li, dl, dt, dd, blockquote, figure, fieldset, legend, textarea, pre, iframe, hr, h1, h2, h3, h4, h5, h6 {
    padding: revert;
    margin: revert;
  }
  ul {
    list-style-type: disc;
  }
  mark {
    background-color: #faf594;
  }
}
</style>